import React, { useState, useEffect } from 'react';
import { ApiService } from '../api-service';
import '../styles/WinnerList.css';
import { Redirect, Link } from 'react-router-dom';

interface Winner {
    year: number;
    name: string;
    course: string;
}

function WinnerList() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [winners, setWinners] = useState<Winner[]>([]);

    useEffect(() => { 
        const csrfCookie = ApiService.getCsrfCookie()
            .subscribe(val => console.log('csrf', val));
        
        csrfCookie.unsubscribe();

        const winnersResponse = ApiService.get('tournaments-v1/winners')
            .subscribe(
                winners => {
                    console.log(winners);
                    setWinners(winners);
                    setIsLoaded(true);
                },
                error => {
                    setError(error);
                    setIsLoaded(true);
                }
            );;

        return () => {
            winnersResponse.unsubscribe();
        };

    }, []);

    if (error) {
        return <Redirect to='/loginpage' />;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
            <article className="winner-list">
                <h2>
                    Vinnare av LGBAGT
                </h2>
                <ul>
                    {winners.map(winner => (
                        <li key={winner.year}>
                            {winner.year} - {winner.name} - {winner.course}
                        </li>
                    ))}
                </ul>   
                <Link to="/tournament/create">Skapa turnering</Link>
            </article>
        );
      }
};

export default WinnerList;