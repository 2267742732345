import { Observable } from 'rxjs'; 
import BaseRequestModel from './base-request-model';
import { Body } from './types';

export const ApiService = {
    getCsrfCookie: (): Observable<any> => {
        const headers = { 'Content-Type': 'text/html' };
        const getCsrfCookie = new BaseRequestModel('GET', 'https://api.lgbagt.johannlindell.se/sanctum/csrf-cookie', headers);

        return getCsrfCookie.request();
    },

    get: (route: string): Observable<any> => {
        const headers = {};
        const get = new BaseRequestModel('GET', route, headers);
        
        return get.request();
    },

    post: (route: string, body: Body): Observable<any> => {
        const headers = {};
        const post = new BaseRequestModel('POST', route, headers, body);
        
        return post.request();
    }
};