import React from 'react';

interface TextFieldProps {
    name: string;
    placeholder: string;
    isRequired: boolean;
    maxLength: number;
    register: any;
    errors: any;
}

function TextField (props: TextFieldProps) {
    const { name, placeholder, isRequired, maxLength, register} = props;
    
    return <input type='text' placeholder={placeholder} name={name} required={isRequired} max={maxLength} ref={register()} />;
}

export default TextField;