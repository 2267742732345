import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DateTimePicker from 'react-datetime-picker';
import { withRouter } from 'react-router-dom';

import TextField from './Forms/TextField';

import '../styles/App.css';
import '../styles/CreateTournamentForm.css';

import { ApiService } from '../api-service';

interface TournamentInfo {
    id: number | null;
    course: string;
    message: string;
    date: any;
}

function CreateTournamentForm(props : any) {
    const {handleSubmit, control, errors, register } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const addLeadingZero = (value : number) : string => {
        if (value < 10) {
            return '0' + value;
        }

        return '' + value;
    }

    const toDateTimeString = (date: Date) : string => {
        return date.getFullYear() + '-' +
            addLeadingZero(date.getMonth() + 1) + '-' +
            addLeadingZero(date.getDate()) + ' ' +
            addLeadingZero(date.getHours()) + ':' +
            addLeadingZero(date.getMinutes());
    }

    const submit = (data: TournamentInfo) => {
        setIsLoading(true);
        data.date = toDateTimeString(data.date);

        const submit = ApiService.post('tournaments-v1', JSON.stringify(data))
            .subscribe(
                tournament => {
                    setIsLoading(false);
                    submit.unsubscribe();
                    props.history.push('/tournament/show/' + tournament.id);
                },
                error => {
                    setIsLoading(false);
                    setError(error);
                    console.error(error);
                    submit.unsubscribe();
                }
            );
    };

    if(error) {
        return <div>Error: {'something went wrong'} ({error.status}) </div>;
    } else if(isLoading) {
        return <div>Loading...</div>
    } else {
        return (
            <>
                <h2>Skapa nytt evenemang</h2>
                <form onSubmit={handleSubmit(submit)} className='create-tournament'>
                <Controller
                        control={control}
                        name="date"
                        render={({ onChange, value=new Date() }) => (
                            <DateTimePicker
                                onChange={onChange}
                                value={value}
                                minDate={new Date()}
                                required
                                format='y-MM-dd HH:mm'
                            />
                        )}
                    />
                    <br />
                    <TextField name='course' placeholder='Bana' isRequired={true} maxLength={255} register={register} errors={errors.course}/>
                    <br />
                    <textarea name='message' placeholder='Information' required maxLength={1023} ref={register()} rows={23}/>
                    <br />
                    <button type="submit">Skapa evenemang</button>
                </form>
            </>
        );
    }
};

export default withRouter(CreateTournamentForm);