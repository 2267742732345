import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiService } from '../api-service';

interface TournamentInfo {
    course: string;
    message: string;
    date: Date | null;
}

function Tournament() {
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tournament, setTournament] = useState<TournamentInfo>({
        course: 'Loading...',
        message: 'Loading...',
        date: null
    });

    const convertDateForIos = (dateString: any) : Date => {
        const arr = dateString.split(/[- :]/);
        return new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    }

    let { id } = useParams<{id: string}>();
    
    useEffect(() => {
        const request = ApiService.get('tournaments-v1/' + id)
            .subscribe(
                tournamenResponse => {
                    tournamenResponse.date = convertDateForIos(tournamenResponse.date);
                    setTournament(tournamenResponse);
                    setIsLoaded(true);
                },
                err => {
                    console.error(err);
                    setError(err);
                    setIsLoaded(true);
                }
            );

        return () => {
            request.unsubscribe();
        };
    }, []);

    if (error) {
        return <div>Error: {'something went wrong'} ({error.status})</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else if (tournament.date !== null){
        return (
            <article className="tournament">
                <h2>
                    {tournament.course} - {tournament.date.getFullYear()}
                </h2>
                <div>
                    När? <span style={{fontWeight: 'bold'}}>
                            {tournament.date.getDate()}/{tournament.date.getMonth() + 1} {tournament.date.getFullYear()}
                        </span>
                </div>
                <br />
                <div>
                    {tournament.message}
                </div>
            </article>
        );
      } else {
          return <div>Something went really, really wrong</div>
      }
}

export default Tournament;