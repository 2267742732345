import { Method, _Headers, Body } from './types';
import { Observable } from 'rxjs';

const baseUrl = 'https://api.lgbagt.johannlindell.se/api/';
//const baseUrl = 'http://localhost:8080/api/';

interface Props {
    url: string;
    method: Method;
            
    headers: _Headers;
    body?: Body;
};

export default class BaseRequestModel implements Props {
    url: string;
    method: Method;
    headers: _Headers;
    body: Body;

    constructor(method: Method, url: string, headers: _Headers, body?: Body) {
        this.url = url;
        this.method = method;
        this.headers = { ...this.jsonHeaders(), ...headers };
        this.body = body;
    }

    input(): RequestInfo {
        if (this.url.includes('http')) {
            return this.url;
        }

        return `${baseUrl}${this.url}`;
    }

    init(): RequestInit {
        return {
            method: this.method,
            headers: this.headers,
            body: this.body,
            credentials: 'same-origin'
        };
    }

    getXsrfToken(): string {
        var name = 'XSRF-TOKEN=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    jsonHeaders(): object {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-XSRF-TOKEN': this.getXsrfToken()
        }
    }

    isResponseOk(response: Response): boolean {
        return response.status < 400;
    }

    isResponseJson(headers: Headers): boolean {
        return headers.get('Content-Type') === 'application/json';
    }

    handle(response: Response): any {
        if (this.isResponseJson(response.headers)) {
            return response.json();
        }

        return '';
    }


    request(): Observable<any> {
        return new Observable(observer => {
            fetch(
                this.input(),
                this.init()
            ).then((response: Response) => {
                if (this.isResponseOk(response)) {
                    return this.handle(response);
                }
                console.error(response);
                observer.error(response);
            }).then((data: any) => {
                observer.next(data);
                observer.complete();
            }).catch((error: any) => {
                observer.error(error);
            })

            return () => {
                // clean up on unsubscribe
            }
        });
    }
}