import React from 'react';
import { Route } from 'react-router-dom';
import WinnerList from './components/WinnerList';
import CreateTournamentForm from './components/CreateTournamentForm';
import Tournament from './components/Tournament';
import Login from './components/auth/Login';

function Routes() {
    return (
        <>  
            <Route exact path='/' component={ WinnerList } />
            <Route exact path='/tournament/create' component={ CreateTournamentForm } />
            <Route exact path='/tournament/show/:id' component={ Tournament } />
            <Route exact path='/loginpage' component={ Login } />
        </>
    );
};

export default Routes;