import React from 'react';
import { useForm } from 'react-hook-form';

import TextField from '../Forms/TextField';

import { ApiService } from '../../api-service';

function Login() {
    const {handleSubmit, errors, register } = useForm();

    const submit = (data: any) => {
        console.log('submit, data');
        const csrfCookie = ApiService.getCsrfCookie()
            .subscribe(val => console.log('csrf', val));
        csrfCookie.unsubscribe();

        const login = ApiService.post('https://api.lgbagt.johannlindell.se/login', JSON.stringify(data))
            .subscribe(
                response => {
                    console.log('login', response);
                    login.unsubscribe();
                },
                error => {
                    console.error('login', error);
                    login.unsubscribe();
                }
            )
    }

    return (
        <>
            <h2>Login page</h2>
            <form onSubmit={handleSubmit(submit)} className='create-tournament'>
                <TextField name='email' placeholder='Användarnamn' isRequired={true} maxLength={255} register={register} errors={errors.course}/>
                <br />
                <TextField name='password' placeholder='Lösenord' isRequired={true} maxLength={255} register={register} errors={errors.course}/>
                <br />
                <button type="submit">Logga in</button>
            </form>
        </>
    );
}

export default Login;